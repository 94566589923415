body {
  overflow-x: hidden;
}

// Mixin - Pseudo element
@mixin pseudo-element {
  content: '';
  position: absolute;
  display: block;
}

// Logo

@media (min-width: map-get($breakpoints, lg) + 1px) {
  .mainLogo{
    top:20px;
  }
}

#pageHeader {
  padding-bottom: 20px;
}

// Carousel - Layout - allow the slide detail to overlap
.carousel { z-index: 1; }
.carouselSlide { overflow: visible; }

// Home Intro 
.homeIntro {
  z-index: 1;
  padding: #{$card-gap-width / 2};
  @media (max-width: map-get($breakpoints, lg)) {
    max-width: none;
    margin-bottom: $spacer * 2;
  }
  > * { color: text-contrast($home-features-background-colour); }
  h2{
    @include heading-underline();
  }
}

// Home Features - Layout
.homeFeatures{
  padding-left: 0;
  padding-right: 0;
}
.homeFeaturesInner {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  @media (max-width: map-get($breakpoints, lg)) {
    max-width: 100%;
  }
}

// Impact section - Layout
.homefeaturecategory-homeboximpactstats .homeImpactWrapper {
  padding:  0;
  flex-direction: unset;
  flex-wrap: wrap;
  
  // Impact table - Layout
  table {
    margin-right: 0;
    margin-top: 0;
    max-width: calc(100% - 350px);
    @media (min-width: map-get($breakpoints, md)) {
      tr td {
        padding-right: 40px;
      }
    }
    @media (max-width: map-get($breakpoints, lg)) {
      max-width: 100%;
      margin: 15px;
    }
  }

  // Impact title - Layout
  > h2 {
    padding: #{$card-gap-width / 2};
    @include heading-underline(
      $colour: $brand-secondary
    );

  }
}

// Home Feeds - Layout
.homeFeed {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  //Feed - Background
  &:before {
    @include pseudo-element;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
  }
}

// Feed specific
ul.feedList {
  width: 100%;
  max-width: calc(100% - 350px);
  margin: 0 0 0 auto;
  @media (max-width: map-get($breakpoints, lg)) {
    max-width: 100%;
  }
}

// Feeds Title - Layout
.feedsTitle {
  width: auto;
  max-width: 350px;
  position: relative;
  margin: 0;  
}

// Feed Specific - Odd
.homeFeed:nth-of-type(odd) {
  background-color: $brand-secondary;
  &:before { background-color: $brand-secondary; }
  .feedsTitle { 
    color: text-contrast($brand-secondary); 
    @include heading-underline(
      $colour: $brand-primary
    );
  }
}

// Feed Specific - Even
.homeFeed:nth-of-type(even) {
  background-color: $brand-primary;
  &:before { background-color: $brand-primary; }
  .feedsTitle { 
    color: text-contrast($brand-primary); 
    @include heading-underline(
      $colour: $brand-secondary
    );
  }
}

.Footer-credits{
  font-size: $font-size-small;
}

.pageFooter {
  .cta-button {
    color: #fff !important;
    text-decoration: none !important;
  }
}

// Donation forms - hide 'Display your name with your donation on our site'

.formQuestion.displayName.labelCheckBoxPair {
  display: none;
}